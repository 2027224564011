import { ref } from 'vue'

const searchQuery = ref({
    q: '',
    status: '',
    rooms: '',
    bedrooms: '',
    bathrooms: '',
    selected_place: '',
    radius: 0,
    type: 'selling',
    country: '',
    price: {
        from: '',
        to: ''
    },
    coordinates: {
        latitude: '',
        longitude: ''
    },
    plot_area: {
        from: '',
        to: ''
    },
    construction_type: '',
    features: {
        construction: {
            property_type: [],
            type: [],
            property_orientation: [],
            year_of_construction: []
        },
        gated_resort: [],
        pool: [],
        furnished: []
    },
    range: {
        features: {
            areas: {
                living_area: {
                    from: '',
                    to: ''
                },
                plot_size: {
                    from: '',
                    to: ''
                }
            },
            layout: {
                numbers_of_rooms: {
                    from: '',
                    to: ''
                },
                numbers_of_bedrooms: {
                    from: '',
                    to: ''
                },
            },
        }
    }
})

const showSearchFilterModal = ref(false)

export const useSearch = () => {

    const executeSearch = () => {
        const params = new URLSearchParams(Object.fromEntries(Object.entries(getUrlQuery()).filter(([_, v]) => v != '')))

        window.location.href = `/s/${ (searchQuery.value.selected_place)? searchQuery.value.selected_place.description : `around-the-world` }/selling?${ params.toString() }`
    }

    const clearSearchQuery = () => {
        searchQuery.value ={
            q: '',
            status: '',
            rooms: '',
            bedrooms: '',
            bathrooms: '',
            selected_place: '',
            from_price: '',
            to_price: '',
            radius: 0,
            coordinates: {
                latitude: '',
                longitude: ''
            },
            living_area: {
                from: '',
                to: ''
            },
            plot_area: {
                from: '',
                to: ''
            },
            construction_type: '',
            features: {
                gated_resort: [],
                pool: [],
                energy_labels: []
            }
        }
    }

    const setSearchQuery = (queries) => {
        const price = (queries.price)? queries.price.split('-') : ''
        const living_area = (queries.range && queries.range.features && queries.range.features.areas && queries.range.features.areas.living_area)? queries.range.features.areas.living_area.split('-') : []
        const plot_size = (queries.range && queries.range.features && queries.range.features.areas && queries.range.features.areas.plot_size)? queries.range.features.areas.plot_size.split('-') : []
        const numbers_of_rooms = (queries.range && queries.range.features && queries.range.features.layout && queries.range.features.layout.numbers_of_rooms)? queries.range.features.layout.numbers_of_rooms.split('-') : []
        const numbers_of_bedrooms = (queries.range && queries.range.features && queries.range.features.layout && queries.range.features.layout.numbers_of_bedrooms)? queries.range.features.layout.numbers_of_bedrooms.split('-') : []

        searchQuery.value = {
            selected_place: {
                place_id: queries.place_id ?? '',
            },
            type: queries.type,
            radius: queries.radius ?? '',
            country: queries.country ?? '',
            price: {
                from: (price.length > 0)? price[0] : '',
                to: (price.length > 1)? price[1] : '',
            },
            features: {
                construction: {
                    property_type: (queries.features && queries.features.construction && queries.features.construction.property_type)? queries.features.construction.property_type.split(',') : [],
                    type: (queries.features && queries.features.construction && queries.features.construction.type)? queries.features.construction.type.split(',') : [],
                    property_orientation: (queries.features && queries.features.construction && queries.features.construction.property_orientation)? queries.features.construction.property_orientation.split(',') : [],
                    year_of_construction: (queries.features && queries.features.construction && queries.features.construction.year_of_construction)? queries.features.construction.year_of_construction.split(',') : []
                }
            },
            range: {
                features: {
                    areas: {
                        living_area: {
                            from: (living_area.length > 0)? living_area[0] : '',
                            to: (living_area.length > 1)? living_area[1] : ''
                        },
                        plot_size: {
                            from: (plot_size.length > 0)? plot_size[0] : '',
                            to: (plot_size.length > 1)? plot_size[1] : ''
                        },
                    },
                    layout: {
                        numbers_of_rooms: {
                            from: (numbers_of_rooms.length > 0)? numbers_of_rooms[0] : '',
                            to: (numbers_of_rooms.length > 1)? numbers_of_rooms[1] : ''
                        },
                        numbers_of_bedrooms: {
                            from: (numbers_of_bedrooms.length > 0)? numbers_of_bedrooms[0] : '',
                            to: (numbers_of_bedrooms.length > 1)? numbers_of_bedrooms[1] : ''
                        }
                    }
                }
            }
        }
    }

    const getUrlQuery = () => {
        return {
            place_id: (searchQuery.value.selected_place)? searchQuery.value.selected_place.place_id : '',
            price: (searchQuery.value.price.from || searchQuery.value.price.to)? `${ searchQuery.value.price.from }-${ searchQuery.value.price.to }` : '',
            type: searchQuery.value.type,
            radius: searchQuery.value.radius,
            country: searchQuery.value.country,
            'features[construction][property_type]': searchQuery.value.features.construction.property_type.join(','),
            'features[construction][type]': searchQuery.value.features.construction.type.join(','),
            'features[construction][property_orientation]': searchQuery.value.features.construction.property_orientation.join(','),
            'features[construction][year_of_construction]': searchQuery.value.features.construction.year_of_construction.join(','),
            'range[features][areas][living_area]': (searchQuery.value.range.features.areas.living_area.from || searchQuery.value.range.features.areas.living_area.to)? `${ searchQuery.value.range.features.areas.living_area.from }-${ searchQuery.value.range.features.areas.living_area.to }` : '',
            'range[features][areas][plot_size]': (searchQuery.value.range.features.areas.plot_size.from || searchQuery.value.range.features.areas.plot_size.to)? `${ searchQuery.value.range.features.areas.plot_size.from }-${ searchQuery.value.range.features.areas.plot_size.to }` : '',
            'range[features][layout][numbers_of_rooms]': (searchQuery.value.range.features.layout.numbers_of_rooms.from || searchQuery.value.range.features.layout.numbers_of_rooms.to)? `${ searchQuery.value.range.features.layout.numbers_of_rooms.from }-${ searchQuery.value.range.features.layout.numbers_of_rooms.to }` : '',
            'range[features][layout][numbers_of_bedrooms]': (searchQuery.value.range.features.layout.numbers_of_bedrooms.from || searchQuery.value.range.features.layout.numbers_of_bedrooms.to)? `${ searchQuery.value.range.features.layout.numbers_of_bedrooms.from }-${ searchQuery.value.range.features.layout.numbers_of_bedrooms.to }` : '',
        }
    }

    return {
        searchQuery,
        showSearchFilterModal,
        clearSearchQuery,
        executeSearch,
        setSearchQuery,
        getUrlQuery
    }
}
