<template>
    <div class="lg:w-1/3 lg:static fixed w-full z-30 h-full bg-white ">
        <SimpleBar class="p-5 h-full">
            <h2 class="font-semibold text-lg py-3">{{ $t('search_filters.filters') }}</h2>

            <button class="fixed top-5 right-5 rounded-full w-11 h-11 border-2 bg-white shadow-xl lg:hidden" @click="$emit('closeFilter')">
                <i class="fi fi-rr-cross-small flex items-center justify-center text-xl text-red-500"></i>
            </button>

            <div class="py-5 space-y-3 text-sm">
                <h2 class="font-semibold text-gray-600">Sort</h2>

                <div class="flex space-x-2 items-center">
                    <div class="h-12 border w-full rounded overflow-hidden relative">
                        <select class="w-full h-full bg-white px-2 text-sm appearance-none focus:outline-none hover:cursor-pointer select-none" v-model="sortBy">
                            <option value="relevance">Relevance</option>
                            <option value="date_newest_oldest">Date - Newest to oldest</option>
                            <option value="date_oldest_newest">Date: Oldest to newest</option>
                            <option value="price_high_low">Price - High to low</option>
                            <option value="price_low_high">Price - Low to high</option>
                        </select>

                        <i class="fi fi-rr-angle-small-down absolute right-2 translate-y-1/2 text-primary text-xl"></i>
                    </div>
                </div>
            </div>

            <div class="py-5 space-y-3 text-sm">
                <h2 class="font-semibold text-gray-600">{{ $t('search_filters.price') }}</h2>

                <div class="flex space-x-2 items-center">
                    <div class="rounded border h-12 w-1/2 px-2 flex items-center space-x-1">
                        <span>{{ preferences.currency.symbol_icon }}</span>
                        <price-input v-model="searchQuery.price.from" class="flex-1 w-0 h-full focus:outline-none" :placeholder="$t('search_filters.minimum_price')" />
                    </div>

                    <div class="text-center">To</div>

                    <div class="rounded border h-12 w-1/2 px-2 flex items-center space-x-1">
                        <span>{{ preferences.currency.symbol_icon }}</span>
                        <price-input v-model="searchQuery.price.to" class="flex-1 w-0 h-full focus:outline-none" :placeholder="$t('search_filters.maximum_price')" />
                    </div>
                </div>
            </div>

            <div v-if="filters">
                <SearchFilterOptions v-if="filters.property_types.length" :name="$t(`features.property_type`)" :options="filters.property_types" v-model="searchQuery.features.construction.property_type" />

                <SearchFilterOptions v-if="filters.property_orientations.length" :name="$t(`features.orientation`)" :options="filters.property_orientations" v-model="searchQuery.features.construction.property_orientation" />

                <SearchFilterOptions v-if="filters.year_of_construction.length" :name="$t(`features.year_of_construction`)" :options="filters.year_of_construction" v-model="searchQuery.features.construction.year_of_construction" />

                <!-- <SearchFilterOptions v-if="filters.statuses.length" name="status" :options="filters.statuses" v-model="searchQuery.features.construction.year_of_construction" /> -->

                <SearchFilterRangeSelect :name="$t(`features.living_area`)" :options="[
                    {
                        value: 0,
                        displayValue: '0 m²'
                    },
                    {
                        value: 50,
                        displayValue: '50 m²'
                    },
                    {
                        value: 75,
                        displayValue: '75 m²'
                    },
                    {
                        value: 100,
                        displayValue: '100 m²'
                    },
                    {
                        value: 125,
                        displayValue: '125 m²'
                    },
                    {
                        value: 150,
                        displayValue: '150 m²'
                    },
                    {
                        value: 175,
                        displayValue: '175 m²'
                    },
                    {
                        value: 200,
                        displayValue: '200 m²'
                    },
                    {
                        value: 250,
                        displayValue: '250 m²'
                    },
                ]" v-model="searchQuery.range.features.areas.living_area" />

                <SearchFilterRangeSelect :name="$t(`features.plot_area`)" :options="[
                    {
                        value: 0,
                        displayValue: '0 m²'
                    },
                    {
                        value: 250,
                        displayValue: '250 m²'
                    },
                    {
                        value: 500,
                        displayValue: '500 m²'
                    },
                    {
                        value: 750,
                        displayValue: '750 m²'
                    },
                    {
                        value: 1000,
                        displayValue: '1000 m²'
                    },
                    {
                        value: 1500,
                        displayValue: '1500 m²'
                    },
                    {
                        value: 2500,
                        displayValue: '2500 m²'
                    },
                    {
                        value: 5000,
                        displayValue: '5000 m²'
                    }
                ]" v-model="searchQuery.range.features.areas.plot_size" />

                <SearchFilterRangeSelect :name="$t(`features.rooms`)" :options="[
                    {
                        value: 0,
                        displayValue: '0'
                    },
                    {
                        value: 1,
                        displayValue: '1'
                    },
                    {
                        value: 2,
                        displayValue: '2'
                    },
                    {
                        value: 3,
                        displayValue: '3'
                    },
                    {
                        value: 4,
                        displayValue: '4'
                    },
                    {
                        value: 5,
                        displayValue: '5'
                    }
                ]" v-model="searchQuery.range.features.layout.numbers_of_rooms" />

                <SearchFilterRangeSelect :name="$t(`features.bed_rooms`)" :options="[
                    {
                        value: 0,
                        displayValue: '0'
                    },
                    {
                        value: 1,
                        displayValue: '1'
                    },
                    {
                        value: 2,
                        displayValue: '2'
                    },
                    {
                        value: 3,
                        displayValue: '3'
                    },
                    {
                        value: 4,
                        displayValue: '4'
                    },
                    {
                        value: 5,
                        displayValue: '5'
                    }
                ]" v-model="searchQuery.range.features.layout.numbers_of_bedrooms" />

                <!-- <SearchFilterOptions v-if="filters.energy_labels.length" :name="$t(`features.energy_label`)" :options="filters.energy_labels" v-model="searchQuery.features.construction.energy_labels" /> -->

            </div>
        </SimpleBar>
    </div>
</template>


<script>
	import { ref } from 'vue'
    import { useSearch } from '../lib/search'
    import PriceInput from './PriceInput.vue'
    import CheckboxInput from './CheckboxInput.vue'
    import SearchFilterOptions from './SearchFilterOptions.vue'
    import SearchFilterRangeSelect from './SearchFilterRangeSelect.vue'
    import { useApi } from '../lib/api'
    import { usePreferences } from '../lib/preferences'
    import { useHelpers } from '../lib/helpers'
    import SimpleBar from './SimpleBar.vue'

    export default {
	    props: ['sortBy'],
        components: {
            PriceInput,
            CheckboxInput,
            SearchFilterOptions,
            SearchFilterRangeSelect,
            SimpleBar
        },
        watch: {
            searchQuery: {
                handler(v) {
                    var vm = this

                    const urlQuery = this.getUrlQuery()

                    var fullUrl = window.location.pathname + window.location.search;

                    Object.keys(urlQuery).forEach(function(key) {
                        fullUrl = vm.updateQueryStringParameter(fullUrl, key, urlQuery[key])
                    });

                    window.history.pushState('page', 'page', fullUrl);

                    this.$emit('queriesUpdated')
                },
                deep: true
            },
            sortBy(value) {
                var fullUrl = window.location.pathname + window.location.search;

                fullUrl = this.updateQueryStringParameter(fullUrl, 'sort_by', value)

                window.history.pushState('page', 'page', fullUrl);

                this.$emit('update:sort-by', value)
                this.$emit('queriesUpdated')
            },
            '$props.sortBy'(value) {
                this.sortBy = value
            }
        },
	    setup(props) {
            const { searchQuery, getUrlQuery } = useSearch()
            const { get, loading, data } = useApi('listing/filters')
            const { updateQueryStringParameter } = useHelpers()

            const filters = ref(null)
            const { preferences } = usePreferences()
            const sortBy = ref(props.sortBy ?? 'relevance')

            get(getUrlQuery()).then(() => {
                if(data.value.status) {
                    filters.value = data.value.filters
                }
            })

            return {
                searchQuery,
                filters,
                preferences,
                updateQueryStringParameter,
                getUrlQuery,
                sortBy
            }
		}
	}
</script>
